import {
    CLOCK_URL
} from './urls';

function canShare() {
    if (process.env.REACT_APP_TYPE === 'android') {
        //@ts-ignore
        return window && window.plugins && window.plugins.socialsharing;
    } else if (process.env.REACT_APP_TYPE === 'ios') {
        return true;
    }
    return true;
}

function onShare(title: string, text: string, clockCode: string, url: string = `${CLOCK_URL}?code=%clock`) {
    let message: any = {
        title,
        text,
        url: url
    };

    message.url = message.url.replace('%clock', clockCode);

    if (process.env.REACT_APP_TYPE === 'android') {
        const options = {
            message: message?.text || '', // not supported on some apps (Facebook, Instagram)
            subject: message?.title || '', // fi. for email
            url: message?.url || ''
        };

        const onSuccess = function (result: any) {
            console.log("[SHARE] Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        };

        const onError = function (msg: any) {
            console.log("[SHARE] Sharing failed with message: " + msg);
        };

        //@ts-ignore
        if (window && window.plugins && window.plugins.socialsharing) {
            //@ts-ignore
            window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        }
    } else {
        const messageTitle = message?.title || '';
        const messageText = message?.text || '';
        const messageUrl = message?.url || '';

        try {
            navigator.share(
                {
                    title: messageTitle,
                    text: messageText,
                    url: messageUrl
                }
            );
        } catch (e) {
        }
    }
}



export {
    canShare,
    onShare
}