import {
    observer
} from 'mobx-react-lite';
import { Container, ImageComponent, ActionButton } from '../controls';
import LevelEdit from './editor/LevelEdit';
import GeneralEdit from './editor/GeneralEdit';
import ClockSummary from './editor/ClockSummary';
import PayEdit from './editor/PayEdit';
import EventEdit from './editor/EventEdit';
import PayoutEdit from './editor/PayoutEdit';
import PremiumWizard from './editor/PremiumWizard';
import TemplateFile from './editor/TemplateFile';

const ClockWizard = observer(({ sdk }: any) => {

    let useStore = null;

    if (sdk.clockWizard === 1) {
        useStore = sdk.clockGeneralEditor;
    } else if (sdk.clockWizard === 2) {
        useStore = sdk.clockBlindEditor;
    } else if (sdk.clockWizard === 3) {
        useStore = sdk.clockEventEditor;
    } else if (sdk.clockWizard === 4) {
        useStore = sdk.clockPayEditor;
    } else if (sdk.clockWizard === 5) {
        useStore = sdk.clockPayoutEditor;
    }

    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [50, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            {sdk.clockWizard === 0 ? 'Premium Wizard' : `Wizard ${sdk.clockWizard} / 6`}
        </Container>
        <Container
            id={'wizard-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 1000,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 980,
                    margin: [10, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left'
                }}
            >
                {sdk.templateStore.active ?
                    <TemplateFile sdk={sdk} />
                    : (
                        <>
                            {sdk.clockWizard === 0 ? <PremiumWizard sdk={sdk} /> : null}
                            {sdk.clockWizard === 1 ? <GeneralEdit sdk={sdk} store={sdk.clockGeneralEditor} /> : null}
                            {sdk.clockWizard === 2 ? <LevelEdit sdk={sdk} store={sdk.clockBlindEditor} /> : null}
                            {sdk.clockWizard === 3 ? <EventEdit sdk={sdk} store={sdk.clockEventEditor} /> : null}
                            {sdk.clockWizard === 4 ? <PayEdit sdk={sdk} store={sdk.clockPayEditor} /> : null}
                            {sdk.clockWizard === 5 ? <PayoutEdit sdk={sdk} store={sdk.clockPayoutEditor} /> : null}
                            {sdk.clockWizard === 6 ? <ClockSummary sdk={sdk} /> : null}
                        </>
                    )}
            </Container>
        </Container>
        {sdk.templateStore.active ?
            <ActionButton
                actionColor={'red'}
                onClick={() => sdk.templateStore.reset()}
                buttonOption={{
                    widthPx: 180,
                    heightPx: 96,
                    lineHeight: 80,
                    heightToWidth: undefined,
                    margin: [16, 16, 16, 64],
                    padding: [0, 8, 0, 8],
                    textAlign: 'center'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [16, 0, 0, 0]
                }}
                icon={'./data/icon/previous.svg'}
            >
                Back
            </ActionButton>
            : (
                <>
                    <ActionButton
                        actionColor={'black'}
                        onClick={() => sdk.previousWizardStep()}
                        disabled={sdk.clockWizard < 2 || sdk.clockWizard >= 5}
                        buttonOption={{
                            widthPx: 180,
                            heightPx: 96,
                            lineHeight: 80,
                            heightToWidth: undefined,
                            margin: [16, 16, 16, 64],
                            padding: [0, 8, 0, 8],
                            textAlign: 'center'
                        }}
                        iconOption={{
                            widthPx: 48,
                            heightPx: 48,
                            margin: [16, 0, 0, 0]
                        }}
                        icon={'./data/icon/previous.svg'}
                    >
                        Previous
                    </ActionButton>
                    <ActionButton
                        actionColor={'red'}
                        onClick={() => sdk.closeWizard()}
                        buttonOption={{
                            widthPx: 180,
                            heightPx: 96,
                            lineHeight: 80,
                            heightToWidth: undefined,
                            margin: [16, 16, 16, 16],
                            padding: [0, 8, 0, 8],
                            textAlign: 'center'
                        }}
                        iconOption={{
                            widthPx: 48,
                            heightPx: 48,
                            margin: [16, 0, 0, 0]
                        }}
                        icon={'./data/icon/leave.svg'}
                    >
                        Leave
                    </ActionButton>
                    <ActionButton
                        actionColor={'black'}
                        onClick={() => sdk.nextWizardStep()}
                        disabled={!useStore?.isValid || sdk.clockWizard >= 6 ? true : false}
                        buttonOption={{
                            widthPx: 180,
                            heightPx: 96,
                            lineHeight: 80,
                            heightToWidth: undefined,
                            margin: [16, 16, 16, 16],
                            padding: [0, 8, 0, 8],
                            textAlign: 'center'
                        }}
                        iconOption={{
                            widthPx: 48,
                            heightPx: 48,
                            margin: [16, 0, 0, 0]
                        }}
                        icon={'./data/icon/next.svg'}
                    >
                        Next
                    </ActionButton>
                </>
            )}

    </>
});

export default ClockWizard;