import {
    observer
} from 'mobx-react-lite';
// import { useEffect } from 'react';
import {
    Container,
    IconButton,
    ActionButton,
    ImageComponent
} from '../controls/index';

const MessageDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk?.dialog?.type === 'message' ? sdk.dialog : null;
    const dialogTitle = dialog?.title || '';
    const dialogMessage = dialog?.message || '';
    const dialogYes = dialog?.cbYes || null;
    const dialogNo = dialog?.cbNo || null;
    const dialogLink = dialog?.link || null;
    const dialogCg = dialog?.couchgames || null;

    const landscapeMode = layoutManager?.portraitMode === false;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: '#062860',
                    margin: landscapeMode ?
                        [(720 - 362) / 2, 0, 0, (1280 - 640) / 2] :
                        [(1280 - 362) / 2, 0, 0, (720 - 640) / 2]
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 48,
                        color: '#fff',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    {dialogTitle}
                </Container>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 32,
                        color: '#fff',
                        fontSize: 32,
                        float: 'left',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 32, 10],
                        role: 'alert'
                    }}
                >
                    {dialogMessage}

                </Container>
                {dialogLink ? (
                    <ActionButton
                        actionColor={'green'}
                        buttonOption={{
                            heightPx: 96,
                            float: 'left',
                            margin: [80, 0, 0, 64]
                        }}
                        onClick={() => {
                            sdk.openAccount()
                            sdk.closeDialog()
                        }}
                    >Account
                    </ActionButton>
                ) : null}
                {dialogCg ? (
                    <ImageComponent
                        source={'./data/cg.png'}
                        imageOption={{
                            widthPx: 160,
                            margin: [64, 0, 0, 16]
                        }}
                    />
                ) : null}
                {dialogNo ? (
                    <IconButton
                        source={'./data/icon/no.svg'}
                        buttonOption={{
                            widthPx: 96,
                            heightPx: 96,
                            float: 'right',
                            margin: [64, 32, 0, 0]
                        }}
                        onClick={() => {
                            if (dialogNo) {
                                dialogNo();
                            }
                            sdk.closeDialog()
                        }}
                    />
                ) : null}
                <IconButton
                    source={'./data/icon/yes.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        if (dialogYes) {
                            dialogYes();
                        }
                        sdk.closeDialog();
                    }}
                />

            </Container>

        </div>
    </div > : null;
});

export default MessageDialog;