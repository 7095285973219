import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx'

const numberInvalid = (v: any, allowEmptyString = false) => {
    if (allowEmptyString && v === '') {
        v = '0'
    }
    if (v !== 0 && !v) {
        return true;
    }
    if (isNaN(parseInt(v, 10))) {
        return true;
    }
    return false;
}

// [0] = bbAnte | 0 = yes | 1 no
// [1] = time 
// [2] = smallBlind
// [3] = bigBlind
// [4] = break | 0 = yes | 1 = no
class BlindStoreLevel {
    public type: number;
    public smallBlind: string;
    public bigBlind: string;
    public ante: string;
    public timeMin: string;
    public screen: string;

    constructor(levelBreak: boolean, time: number, screenId: string = '', sb: number = 0, bb: number = 0, ante: number = 0) {
        makeObservable(this, {
            smallBlind: observable,
            bigBlind: observable,
            timeMin: observable,
            screen: observable,
            ante: observable,
            type: observable,
            updateValue: action,
            switchAnte: action,
            updateAnte: action,
            hasAnte: computed,
            isBreak: computed
        })

        this.type = levelBreak ? 1 : 0;

        this.smallBlind = `${sb}`;
        this.bigBlind = `${bb}`;
        this.ante = `${ante}`;
        this.screen = screenId;
        this.timeMin = `${time}`;
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    public updateAnte(sb: boolean, bb: boolean): void {
        if (sb) {
            this.ante = this.ante === '0' ? this.ante : `${this.smallBlind}`;
            return;
        } else if (bb) {
            this.ante = this.ante === '0' ? this.ante : `${this.bigBlind}`;
        }
    }

    public switchAnte(bbAnte: boolean = false): void {
        if (this.ante === '0') {
            console.log('SW', this.bigBlind, this.smallBlind)
            this.ante = bbAnte ? `${this.bigBlind}` : `${this.smallBlind}`;
        } else {
            this.ante = '0';
        }
    }

    get isBreak() {
        return this.type === 1;
    }

    get hasAnte() {
        return this.ante && this.ante !== '0';
    }

    get config() {
        return this.isBreak ?
            [
                this.type,
                parseInt(this.timeMin, 10),
                this.screen
            ] :
            [
                this.type,
                parseInt(this.timeMin, 10),
                parseInt(this.smallBlind, 10),
                parseInt(this.bigBlind, 10),
                parseInt(this.ante, 10),
                this.screen
            ];
    }
}

export default class BlindStore {

    public adminMode: boolean;

    public blindLevel: Array<BlindStoreLevel>;
    public blindEdit: number;

    public optionStartLevel: string;
    public optionStartTime: string;

    public anteType: number;

    public templateEnabled: number;

    constructor(admin: boolean = false) {
        makeObservable(this, {
            blindLevel: observable,
            blindEdit: observable,
            optionStartLevel: observable,
            optionStartTime: observable,
            anteType: observable,
            adminMode: observable,
            templateEnabled: observable,
            setEditLevel: action,
            reset: action,
            removeLevel: action,
            updateValue: action,
            switchAnteType: action,
            setTemplate: action,
            generate: action,
            addLevel: action,
            addBreak: action,
            load: action,
            loadBlindStructure: action,
            isSbAnte: computed,
            isBbAnte: computed,
            isCustomAnte: computed,
            noLevel: computed,
            blindValid: computed,
            anteTypeLabel: computed,
            changed: computed,
            isValid: computed
        })

        this.adminMode = admin;
        this.blindEdit = 0;
        this.templateEnabled = 0;
        this.anteType = 0;
        this.blindLevel = []
        this.optionStartLevel = '3';
        this.optionStartTime = '10';
    }

    load(clockData: any, password: string = "") {

        this.blindLevel = [];

        const loadLevel = clockData?.config?.level?.data || [];
        const loadedLevel: any = [];

        loadLevel.forEach((lvl: any) => {
            if (lvl[0] === 0) {
                loadedLevel.push(new BlindStoreLevel(false, lvl[1], lvl[5], lvl[2], lvl[3], lvl[4]))
            } else {
                loadedLevel.push(new BlindStoreLevel(true, lvl[1], lvl[2]))
            }
        })

        // Update also the ante Type
        this.anteType = clockData?.config?.level?.anteType || 0;
        this.blindLevel = loadedLevel;

        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    public switchAnteType(): void {
        this.anteType += 1;
        if (this.anteType > 2) {
            this.anteType = 0;
        }

        this.blindLevel.forEach((lvl) => lvl.updateAnte(this.isSbAnte, this.isBbAnte))
    }

    get anteTypeLabel() {
        if (this.isSbAnte) return 'Ante: SB';
        if (this.isBbAnte) return 'Ante: BB';
        return 'Ante: Custom';
    }

    public addBreak(lvl: number, pos: number): void {
        const nextLevelArray: Array<BlindStoreLevel> = [];
        this.blindEdit = 0;

        this.blindLevel.forEach((level: BlindStoreLevel, i: number) => {
            if (lvl === i && pos === -1) {
                nextLevelArray.push(new BlindStoreLevel(true, 20))
            }

            nextLevelArray.push(level);

            if (lvl === i && pos === +1) {
                nextLevelArray.push(new BlindStoreLevel(true, 20))
            }
        });

        this.blindLevel = nextLevelArray;
    }

    public addLevel(lvl: number, pos: number): void {
        const nextLevelArray: Array<BlindStoreLevel> = [];
        this.blindEdit = 0;

        this.blindLevel.forEach((level: BlindStoreLevel, i: number) => {
            if (lvl === i && pos === -1) {
                nextLevelArray.push(new BlindStoreLevel(false, 20, '', 10, 20, 0))
            }

            nextLevelArray.push(level);

            if (lvl === i && pos === +1) {
                nextLevelArray.push(new BlindStoreLevel(false, 20, '', 10, 20, 0))
            }
        });

        this.blindLevel = nextLevelArray;
    }

    public setTemplate(templateId: number): void {
        switch (templateId) {
            case 1:
                this.blindLevel = [
                    new BlindStoreLevel(false, 30, '', 10, 20, 0),
                    new BlindStoreLevel(false, 30, '', 10, 20, 0),
                    new BlindStoreLevel(false, 30, '', 10, 20, 0)
                ]
                break;
            default:
                this.blindLevel = [
                    new BlindStoreLevel(false, 30, '', 100, 200, 0),
                    new BlindStoreLevel(false, 30, '', 200, 400, 0),
                    new BlindStoreLevel(false, 30, '', 400, 800, 0)
                ]
                break;
        }
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    public removeLevel(lvl: number): void {
        if (this.blindLevel.length > 1) {
            this.blindLevel = this.blindLevel.filter((_level: BlindStoreLevel, i: number) => i !== lvl)
        }
        this.blindEdit = 0;
    }

    public setEditLevel(lvl: number): void {
        if (this.blindEdit === lvl) {
            this.blindEdit = 0;
        } else {
            this.blindEdit = lvl;
        }
    }

    /**
     * Load a current blind structure ( with the given code )
     * @param code 
     * @returns 
     */
    public loadBlindStructure(template: any): void {

        this.anteType = template?.anteType || 0;

        const loadLevel = template?.data || [];
        const loadedLevel: any = [];

        loadLevel.forEach((lvl: any) => {
            if (lvl[0] === 0) {
                loadedLevel.push(new BlindStoreLevel(false, lvl[1], lvl[5], lvl[2], lvl[3], lvl[4]))
            } else {
                loadedLevel.push(new BlindStoreLevel(true, lvl[1], lvl[2]))
            }
        })

        this.blindLevel = loadedLevel;

        // Save in comparison store only if not in admin mode
        if(!this.adminMode) {
            sessionStorage.setItem('comparestore', this.storeValues)
        }

    }


    public generate(): void {
        const startLevel = parseInt(this.optionStartLevel, 10) || 3;
        const startTime = parseInt(this.optionStartTime, 10) || 10;
        const lvlArray: any = [];

        Array.from({ length: startLevel }, () => 0).forEach(() => {
            lvlArray.push(new BlindStoreLevel(false, startTime, '', 10, 20, 0))
        });

        this.blindLevel = lvlArray;
    }

    // Reset the store
    public reset(): void {
        this.blindEdit = 0;
        this.blindLevel = [];
        this.optionStartLevel = '3';
        this.optionStartTime = '10';
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }
        return this.storeValues !== compareStore;
    }

    get blindValid() {
        let foundError = false;

        this.blindLevel.forEach((lvl: BlindStoreLevel) => {
            const lvlData: any = lvl.config;

            if (numberInvalid(lvlData[1]) || lvlData[1] < 1) {
                foundError = true;
            }

            if (!lvl.isBreak) {
                if (numberInvalid(lvlData[2]) || numberInvalid(lvlData[3])) {
                    foundError = true;
                } else if (lvlData[2] > lvlData[3]) {
                    foundError = true;
                } else if (lvlData[2] < 1 || lvlData[3] < 1) {
                    foundError = true;
                }
            }
        })

        return !foundError;
    }

    get isValid() {
        return this.blindLevel.length >= 3 && this.blindValid;
    }

    get isCustomAnte() {
        return this.anteType === 0;
    }

    get isSbAnte() {
        return this.anteType === 1;
    }

    get isBbAnte() {
        return this.anteType === 2;
    }

    get noLevel() {
        return this.blindLevel.length === 0;
    }

    get config() {
        return {
            anteType: this.anteType,
            data: this.blindLevel.map((lvl: BlindStoreLevel) => lvl.config)
        };
    }

    get updatedConfig() {
        return {
            level: this.config
        }
    }

    get storeId() {
        return 'blind'
    }

    get storeValues() {
        return JSON.stringify(this.blindLevel.map((lvl: BlindStoreLevel) => lvl.config));
    }
}