const getCss = (options: any, scaleX: number, scaleY: number) => {
    const useOptions: any = {};
    let fontMargin = undefined;
    let fontPadding = undefined;

    // All options
    if (options.widthPx) useOptions['width'] = `${Math.floor(options.widthPx * scaleX)}px`;
    if (options.width) useOptions['width'] = options.width;
    if (options.touchAction) useOptions['touchAction'] = options.touchAction;
    if (options.height) useOptions['height'] = options.height;
    if (options.maxWidth) useOptions['maxWidth'] = options.maxWidth;
    if (options.wordWrap) useOptions['wordBreak'] = options.wordWrap;
    if (options.heightPx) useOptions['height'] = `${Math.floor(options.heightPx * scaleY)}px`;
    if (options.heightToWidth && options.widthPx) useOptions['height'] = `${Math.floor((options.widthPx / options.heightToWidth) * scaleX)}px`;
    if (options.fontSize) useOptions['fontSize'] = `${Math.round(options.fontSize * scaleX)}px`;
    if (options.lineHeight) useOptions['lineHeight'] = `${Math.round(options.lineHeight * scaleX)}px`;
    if (options.blurEffect) useOptions['filter'] = `blur(${options.blurEffect}px)`;
    if (options.overflow) useOptions['overflow'] = options.overflow;
    if (options.overflowX) useOptions['overflowX'] = options.overflowX;
    if (options.overflowY) useOptions['overflowY'] = options.overflowY;
    if (options.zIndex) useOptions['zIndex'] = options.zIndex;
    if (options.textShadow && options.textShadowColor) {
        useOptions['textShadow'] = `0 0 ${Math.round(options.textShadow * scaleX) || 1}px ${options.textShadowColor}`;
    }
    if (options.pointerEvents) useOptions['pointerEvents'] = options.pointerEvents;
    if (options.float) useOptions['float'] = options.float;
    // if (options.transformRotate) useOptions['transform'] = `rotate(${options.transformRotate}deg)`;
    if (options.display) useOptions['display'] = options.display;
    if (options.opacity) useOptions['opacity'] = options.opacity;
    if (options.listStyle) useOptions['listStyle'] = options.listStyle;
    if (options.color) useOptions['color'] = options.color;
    if (options.className) useOptions['className'] = options.className;
    if (options.top || options.top === 0) useOptions['top'] = `${options.top * scaleY}px`;
    if (options.bottom || options.bottom === 0) useOptions['bottom'] = `${options.bottom * scaleY}px`;
    if (options.left || options.left === 0) useOptions['left'] = `${options.left * scaleX}px`;
    if (options.leftPercent || options.leftPercent === 0) useOptions['left'] = `${options.leftPercent}`;
    if (options.right || options.right === 0) useOptions['right'] = `${options.right * scaleX}px`;
    if (options.position) useOptions['position'] = options.position;
    if (options.textAlign) useOptions['textAlign'] = options.textAlign;
    if (options.animationFillMode) useOptions['animationFillMode'] = options.animationFillMode;
    if (options.tabIndex) useOptions['tabIndex'] = options.tabIndex;
    if (options.whiteSpace) useOptions['whiteSpace'] = options.whiteSpace;
    if (options.writingMode) useOptions['writingMode'] = options.writingMode;
    if (options.bgColor) useOptions['backgroundColor'] = options.bgColor;
    if (options.fontWeight) useOptions['fontWeight'] = options.fontWeight;
    if (options.bgImage) {
        useOptions['backgroundImage'] = options.bgImage;
        if (!options.bgColor) useOptions['backgroundColor'] = 'transparent';
    }
    if (options.bgRepeat) useOptions['backgroundRepeat'] = options.bgRepeat;
    if (options.bgImageSize) useOptions['backgroundSize'] = options.bgImageSize;

    if (options.margin && options.margin.length === 4) {
        const newMargin = [0, 0, 0, 0];

        for (let updMargin = 0; updMargin < 4; updMargin++) {
            newMargin[updMargin] = parseInt(`${options.margin[updMargin] * scaleX}`, 10);
        }
        fontMargin = `${newMargin[0] ? newMargin[0] + "px" : 0} ${newMargin[1] ? newMargin[1] + "px" : 0} ${newMargin[2] ? newMargin[2] + "px" : 0} ${newMargin[3] ? newMargin[3] + "px" : 0}`;
    }

    if (options.padding && options.padding.length === 4) {
        for (let updPadding = 0; updPadding < 4; updPadding++) {
            options.padding[updPadding] = parseInt(`${options.padding[updPadding] * scaleX}`, 10);
        }
        fontPadding = `${options.padding[0] ? options.padding[0] + "px" : 0} ${options.padding[1] ? options.padding[1] + "px" : 0} ${options.padding[2] ? options.padding[2] + "px" : 0} ${options.padding[3] ? options.padding[3] + "px" : 0}`;
    }

    if (fontMargin) useOptions['margin'] = fontMargin;
    if (fontPadding) useOptions['padding'] = fontPadding;

    // Border
    if (options.border && options.borderSize) {
        useOptions['border'] = `${options.borderSize * scaleX}px ${options.border}`;
    } else if (options.border) {
        useOptions['border'] = `${options.border}`;
    } else if (options.borderCorner && Array.isArray(options.borderCorner) && options.borderCorner.length === 4) {
        const rArray: any = [];
        options.borderCorner.forEach((r: any) => {
            rArray.push(`${r * scaleX}px`);
        });
        useOptions['borderRadius'] = `${rArray.join(' ')}`;
    } else if (options.borderLeft && options.borderRight && options.borderSize) {
        useOptions['borderLeft'] = `${options.borderSize * scaleX}px ${options.borderLeft}`;
        useOptions['borderRight'] = `${options.borderSize * scaleX}px ${options.borderRight}`;
    } else if (options.borderRight && options.borderSize) {
        useOptions['borderRight'] = `${options.borderSize * scaleX}px ${options.borderRight}`;
    } else if (options.borderLeft && options.borderSize) {
        useOptions['borderLeft'] = `${options.borderSize * scaleX}px ${options.borderLeft}`;
    } else if (options.borderBottom && options.borderSize) {
        useOptions['borderBottom'] = `${options.borderSize * scaleX}px ${options.borderBottom}`;
    } else if (options.borderTop && options.borderSize) {
        useOptions['borderTop'] = `${options.borderSize * scaleX}px ${options.borderTop}`;
    }

    if (options.borderRadius) {
        useOptions['borderRadius'] = `${options.borderRadius * scaleX}px`
        useOptions['WebkitBorderRadius'] = `${options.borderRadius * scaleX}px`
    }

    if (options.borderRadiusPercent) {
        useOptions['borderRadius'] = `${options.borderRadiusPercent}%`;
        useOptions['WebkitBorderRadius'] = `${options.borderRadiusPercent}%`;
    }

    if (options.filterShadow?.length === 5) {
        const shadow = `drop-shadow(${options.filterShadow[0] * scaleX}px ${options.filterShadow[1] * scaleX}px ${options.filterShadow[2] * scaleX}px  ${options.filterShadow[4]})`;;
        useOptions['filter'] = shadow;
    }
    if (options.boxShadow?.length === 5) {
        const shadow = `${options.boxShadow[0] * scaleX}px ${options.boxShadow[1] * scaleX}px ${options.boxShadow[2] * scaleX}px ${options.boxShadow[4]}`;;
        useOptions['WebkitBoxShadow'] = shadow;
        useOptions['MozBoxShadow'] = shadow;
        useOptions['BoxShadow'] = shadow;
    }
    if (options.outlineBorder && options.outlineBorderSize) {
        const outlineShadow = `0 0 0 ${options.outlineBorderSize * scaleX}px ${options.outlineBorder}`;
        useOptions['WebkitBoxShadow'] = outlineShadow;
        useOptions['MozBoxShadow'] = outlineShadow;
        useOptions['BoxShadow'] = outlineShadow;
    }

    if (options.transform) {
        useOptions['transform'] = options.transform;
        useOptions['WebkitTransform'] = options.transform;
        useOptions['MozTransform'] = options.transform;
    }

    if (options.clipPath) useOptions['clipPath'] = options.clipPath;


    return useOptions;
}

export default getCss;