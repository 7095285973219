import {
    observer
} from 'mobx-react-lite';
import {
    TextField,
    Container,
    ImageComponent
} from '../../controls';
import { openMail } from '../../../utils/urls';

const LayoutEdit = observer(({ sdk, store, editMode }: any) => {

    // const isEditMode: boolean = !!editMode;

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Style Code
            </Container>
            <TextField
                id={'style-code'}
                maxLength={64}
                inputOption={{
                    widthPx: 500,
                    margin: [0, 0, 16, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                    // textAlign: 'left'
                }}
                store={store}
                storeProperty={'layoutCode'}
            />
            <ImageComponent
                source={`./data/ads/clockpremium.png`}
                imageOption={{
                    widthPx: 620,
                    // heightPx: 418,
                    margin: [400, 0, 0, 0]
                }}
                onClick={() => {
                    openMail('marc@bullets.poker')
                }}
            />
        </Container>
    </>;
});

export default LayoutEdit;