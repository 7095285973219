import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react'
import { List, Container, IconButton, ActionButton } from '../../controls';


const PremiumWizard = observer(({ sdk }: any) => {
    const allowTemplateDelete = sdk.user.hasPermission('DELETE.TEMPLATE');

    const templateComponents = sdk.user?.account?.currentLicense?.getTemplateList('tournament')?.templates
        .map((template: any) => (
            <>
                <ActionButton
                    id={'template-single-table'}
                    actionColor={'green'}
                    onClick={() => {
                        sdk.loadClockWithTemplate(template);
                        // store.setTemplate('singletable');
                        // setTemplateView(false);
                    }}
                    icon={'./data/icon/template.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, allowTemplateDelete ? 80 : 96],
                        textAlign: 'center',
                        float: 'left'
                    }}
                >
                    {template.name}
                </ActionButton>
                {allowTemplateDelete ? (
                    <IconButton
                        id={'save'}
                        source={'./data/icon/delete.svg'}
                        onClick={() => {
                            sdk.openDeleteDialog('Really delete tournament?', 'DELETE', () => {
                                sdk.user.deleteTemplate('tournament', template.id).then((success: boolean) => {
                                    if (success) {
                                        // that.reset()
                                    }
                                })
                            })
                        }}
                        // onClick={() => store.deleteTemplate(sdk, store.activeTemplate.type, template.id)}
                        enableDelay={true}
                        // disabled={!store.deletable}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            margin: [32, 0, 8, 32],
                            float: 'left',
                            border: 'none'
                        }}
                    />
                ) : null}
            </>
        ))
    return <>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                float: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Create Tournament from template
        </Container>
        <List
            components={templateComponents}
            containerOption={{
                widthPx: 680,
                heightPx: 680,
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            listOption={{
                heightPx: 128,
                widthPx: 680
            }}
        />
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                float: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Create Tournament from scratch
        </Container>
        <ActionButton
            id={'template-single-table'}
            actionColor={'orange'}
            onClick={() => {
                sdk.nextWizardStep()
            }}
            // disabled={useStore?.isValid ? false : true}
            icon={'./data/icon/template.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                widthPx: 480,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [16, 0, 16, 0],
                textAlign: 'center'
            }}
        >
            New Tournament
        </ActionButton>
    </>
})

export default PremiumWizard;