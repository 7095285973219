import {
    observer
} from 'mobx-react-lite';
import { Container, ImageComponent } from '../controls';

const ClockH1Element = observer(({
    clock
}: any) => {
    const useElementStyle = clock?.style?.['h1title'] || {};
    const useLogoConfig = clock?.style?.layout?.logoConfig || {};
    const useLogoRightConfig = clock?.style?.layout?.logoRightConfig || {};

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 110,
            lineHeight: 110,
            float: 'left',
            color: useElementStyle?.color || '#fff',
            textAlign: 'center',
            fontWeight: useElementStyle?.fontWeight || 'bold',
            bgColor: useElementStyle?.bgColor || undefined,
            // bgColor: '#ff00ff',
            fontSize: useElementStyle?.fontSize || 80
        }}
    >
        {clock.logo ? (
            <ImageComponent
                source={clock.logo}
                imageOption={{
                    position: 'absolute',
                    heightPx: 84,
                    left: 0,
                    margin: [14, 0, 0, 14],
                    ...(useLogoConfig || {})
                }}
            />
        ) : (
            <ImageComponent
                source={'./data/bp_qr.png'}
                imageOption={{
                    position: 'absolute',
                    heightPx: 90,
                    left: 0,
                    margin: [10, 0, 0, 60],
                    ...(useLogoConfig || {})
                }}
            />
        )}
        {clock.h1Label}
        {clock.logoRight ? (
            <ImageComponent
                // source={'https://testing.couchgames.wtf/bulletspoker.png'}
                source={clock.logoRight}
                imageOption={{
                    position: 'absolute',
                    heightPx: 84,
                    right: 0,
                    margin: [10, 60, 0, 0],
                    ...(useLogoRightConfig || {})
                }}
            />
        ) : null}
    </Container>
});

const ClockH2Element = observer(({
    clock
}: any) => {
    const useElementStyle = clock?.style?.['h2title'] || {};

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 42,
            lineHeight: 42,
            float: 'left',
            color: useElementStyle?.color || '#fff',
            textAlign: 'center',
            // bgColor: 'rgba(0, 0, 0, 0.4)',
            bgColor: useElementStyle?.bgColor || 'rgba(0, 0, 0, 0.4)',
            fontWeight: useElementStyle?.fontWeight || undefined,
            fontSize: useElementStyle?.fontSize || 30
        }}
    >
        {clock.h2Label}
    </Container>
});

const ClockMainElement = observer(({
    id,
    clock,
    property,
    height,
    fontSize,
    bgColor,
    fontWeight,
    disabled,
    color = '#fff'
}: any) => {
    const useElementStyle = clock?.style?.[id || ''] || {};
    const useLayoutMainConfig = clock?.style?.layout?.[id || ''] || {};

    let useProperty = clock?.[property];
    if (useProperty !== '') {
        useProperty = useProperty || property
    }
    return <Container
        containerOption={{
            width: '100%',
            heightPx: height,
            lineHeight: height,
            bgColor: useElementStyle?.bgColor || bgColor,
            fontSize: useElementStyle?.fontSize || fontSize,
            fontWeight: useElementStyle?.fontWeight || fontWeight,
            color: useElementStyle?.color || color,
            float: 'left',
            ...(useLayoutMainConfig || {})
        }}
    >
        {disabled ? null : useProperty}
    </Container>
})

const ClockSideElement = observer(({ clock, id, title, property, disabled, heightSum, fntLineHeight, fntSize, forceAlign = null, forceMargin = null }: any) => {
    const useElementStyle = clock?.style?.[id || ''] || {};
    const useElementStyleHead = clock?.style?.[id ? `${id}_head` : ''] || {};
    const useElementStyleBody = clock?.style?.[id ? `${id}_body` : ''] || {};

    let useProperty = clock?.[property];
    let hasLineBreak = false;

    if (useProperty !== '') {
        useProperty = useProperty || property
    }

    if (useProperty.includes('\n')) {
        hasLineBreak = true;
    }

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 112 * (heightSum || 1),
            float: 'left',
            bgColor: useElementStyle?.bgColor || undefined
        }}
    >
        {disabled ? null : (
            <>
                <Container
                    containerOption={{
                        heightPx: 42,
                        lineHeight: useElementStyleHead?.lineHeight || 42,
                        margin: [16, 0, 0, 0],
                        fontSize: useElementStyleHead?.fontSize || useElementStyle?.fontSize || 30,
                        color: useElementStyleHead?.color || useElementStyle?.color || undefined,
                        bgColor: useElementStyleHead?.bgColor || undefined
                    }}
                >
                    {title}
                </Container>
                <Container
                    containerOption={{
                        heightPx: 38 * (heightSum || 1),
                        lineHeight: useElementStyleBody?.lineHeight || fntLineHeight || 38,
                        fontSize: useElementStyleBody?.fontSize || useElementStyle?.fontSize || fntSize || 26,
                        color: useElementStyleBody?.color || useElementStyle?.color || '#c6c6c6',
                        bgColor: useElementStyleBody?.bgColor || undefined,
                        textAlign: forceAlign || useElementStyleBody?.align || 'center',
                        margin: forceMargin || useElementStyleBody?.margin || [0, 0, 0, 0]
                    }}
                >
                    {hasLineBreak ? useProperty.split('\n').map((val: any, ni: any) => {
                        return <span key={`payout-${ni}`} style={{ color: useElementStyleBody?.color || useElementStyle?.color || '#c6c6c6' }}>
                            {val}
                            <br />
                        </span>
                    }) : useProperty}
                </Container>
            </>
        )}

    </Container>
})

export const ClockViewRotated = observer(({ sdk }: any) => {
    // const marginRight = sdk.layoutManager.canvasMarginRight;

    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            margin: [0, 0, 0, 0]
            // margin: [0, 0, 0, marginRight]
        }}
    >
        <Container
            className={'rotation-wrapper-outer'}
        >
            <Container
                className={'rotation-wrapper-inner'}
            >
                <Container
                    id={'content'}
                    className={'div-rotated'}
                    containerOption={{
                        widthPx: 1280,
                        heightPx: 720
                    }}
                >
                    <ClockView sdk={sdk} />
                </Container>
            </Container>
        </Container>
    </Container>
})

const ClockView = observer(({ sdk }: any) => {
    const useClock = sdk?.watchClock;
    const useLeftView = useClock?.style?.['leftView'] || {};
    const useMiddleView = useClock?.style?.['middleView'] || {};
    const useRightView = useClock?.style?.['rightView'] || {};

    const useClockLayoutLeft = useClock?.style?.layout?.left || [
        'player',
        'rebuy',
        'totalchips',
        'avgstack',
        'nextbreak'
    ];
    const useClockLayoutRight = useClock?.style?.layout?.right || [
        'pricepool',
        'payout'
    ];
    const useClockShowMiddleBreak = useClock?.style?.layout?.nextBreakMain || false;

    return useClock ? <Container
        containerOption={{
            // bgColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%'
        }}
        onClick={() => sdk.closeClock()}
    >
        <ClockH1Element clock={useClock} />
        <ClockH2Element clock={useClock} />
        <Container
            containerOption={{
                width: '100%',
                heightPx: 568,
                float: 'left',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 'bold'
            }}
        >
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    color: useLeftView?.color || '#fff',
                    bgColor: useLeftView?.bgColor || undefined,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    // bgColor: 'red'
                }}
            >
                {useClockLayoutLeft.map((element: string, i: number) => {
                    switch (element) {
                        case 'player':
                            return useClock.showPlayer ? (
                                <ClockSideElement
                                    id={'player'}
                                    title={'Players'}
                                    clock={useClock}
                                    property={'displayPlayer'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'rebuy':
                            return useClock.showRebuy ? (
                                <ClockSideElement
                                    id={'rebuy'}
                                    title={'Re-Entries'}
                                    clock={useClock}
                                    property={'displayReEntry'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'totalchips':
                            return (useClock.showBuyIn || useClock.showRebuy || useClock.showEarlyBird || useClock.showAddOn) && useClock.chipsInPlay !== 0 ? (
                                <ClockSideElement
                                    id={'totalchips'}
                                    title={'Chips in Play'}
                                    clock={useClock}
                                    property={'displayChipsInPlay'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'avgstack':
                            return (useClock.showBuyIn || useClock.showRebuy || useClock.showEarlyBird || useClock.showAddOn) && useClock.averageStack !== 0 ? (<ClockSideElement
                                id={'avgstack'}
                                title={'Average Stack'}
                                clock={useClock}
                                property={'displayAverageStack'}
                                key={`left-element-${i}`}
                            />) : null;
                        case 'nextbreak':
                            return useClock.hasBreak ? (
                                <ClockSideElement
                                    id={'nextbreak'}
                                    clock={useClock}
                                    title={'Break in'}
                                    disabled={useClock?.break}
                                    property={'timeNextBreak'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'earlybird':
                            return useClock.showEarlyBird ? (
                                <ClockSideElement
                                    id={'player'}
                                    title={'Early Bird'}
                                    clock={useClock}
                                    property={'displayEarlyBird'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        default:
                            break;
                    }
                    return null;
                })}
            </Container>
            <Container
                containerOption={{
                    width: '60%',
                    height: '100%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: useMiddleView?.color || '#fff',
                    bgColor: useMiddleView?.bgColor || undefined,
                }}
            >
                <ClockMainElement
                    clock={useClock}
                    id={'level'}
                    property={'displayCurrentLevel'}
                    height={64}
                    fontSize={28}
                    disabled={useClock?.break}
                />
                {useClock?.break ? (
                    <ClockMainElement
                        clock={useClock}
                        id={'break'}
                        property={'Break'}
                        height={96}
                        color={'#fff'}
                        fontWeight={'bold'}
                        fontSize={80}
                    />
                ) : (
                    <ClockMainElement
                        clock={useClock}
                        id={'blinds'}
                        property={'currentBlinds'}
                        height={96}
                        color={'#c6c6c6'}
                        fontWeight={'bold'}
                        fontSize={80}
                    />
                )}

                <ClockMainElement
                    clock={useClock}
                    id={'ante'}
                    property={'displayAnte'}
                    color={'#c6c6c6'}
                    height={32}
                    fontSize={24}
                />
                <ClockMainElement
                    clock={useClock}
                    id={'time'}
                    property={'timeString'}
                    height={256}
                    bgColor={'rgba(0, 0, 0, 0.4)'}
                    fontWeight={'bold'}
                    fontSize={128}
                />
                {useClockShowMiddleBreak && useClock.hasBreak ? (
                    <ClockMainElement
                        clock={useClock}
                        id={'message'}
                        property={'timeNextBreakOneLine'}
                        disabled={useClock?.break}
                        height={32}
                        fontSize={24}
                    />
                ) : (
                    <ClockMainElement
                        clock={useClock}
                        id={'message'}
                        property={''}
                        height={32}
                        fontWeight={'bold'}
                        fontSize={40}
                    />
                )}
                <ClockMainElement
                    clock={useClock}
                    id={'nextblind'}
                    property={'displayNextBlinds'}
                    disabled={useClock.nextBlinds === '0 / 0'}
                    height={96}
                    color={'#fff'}
                    fontWeight={'bold'}
                    fontSize={40}
                />
            </Container>
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: useRightView?.color || '#fff',
                    bgColor: useRightView?.bgColor || undefined,
                }}
            >
                {useClockLayoutRight.map((element: string, i: number) => {
                    switch (element) {
                        case 'pricepool':
                            return useClock.showPricePool ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'pricepool'}
                                    title={'Prize pool'}
                                    property={'displayPricePool'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'addon':
                            return useClock.showAddOn ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'addon'}
                                    title={'Add-On'}
                                    property={'displayAddon'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={4}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout3':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={3}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout2':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={2}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        default:
                            break;
                    }
                    return null;
                })}
            </Container>
        </Container>
    </Container> : null;
});

export default ClockView;