import {
    observer
} from 'mobx-react-lite';
import { Container, ActionButton } from '../../controls';
import LevelEdit from '../editor/LevelEdit';
import GeneralEdit from '../editor/GeneralEdit';
import PayEdit from '../editor/PayEdit';
import LayoutEdit from '../editor/LayoutEdit';
import EventEdit from '../editor/EventEdit';
import PayoutEdit from '../editor/PayoutEdit';
import TemplateFile from '../editor/TemplateFile';
// import ClockSummary from './editor/ClockSummary';

const ClockEditor = observer(({ sdk, store, clock }: any) => {
    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [50, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            {`Clock Editor`}
        </Container>
        <Container
            id={'wizard-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 1000,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 980,
                    margin: [10, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left'
                }}
            >
                {sdk.templateStore.active ?
                    <TemplateFile sdk={sdk} />
                    : (
                        <>
                            {store?.storeId === 'general' ? <GeneralEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {store?.storeId === 'blind' ? <LevelEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {store?.storeId === 'pay' ? <PayEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {store?.storeId === 'payout' ? <PayoutEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {store?.storeId === 'layout' ? <LayoutEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {store?.storeId === 'event' ? <EventEdit sdk={sdk} store={store} editMode={true} /> : null}
                            {/* {sdk.clockWizard === 2 ? <LevelEdit sdk={sdk} /> : null}
                {sdk.clockWizard === 3 ? <PayEdit sdk={sdk} /> : null}
                {sdk.clockWizard === 4 ? <PayoutEdit sdk={sdk} /> : null}
                {sdk.clockWizard === 5 ? <ClockSummary sdk={sdk} /> : null} */}
                        </>
                    )}
            </Container>
        </Container>
        {sdk.templateStore.active ?
            <ActionButton
                actionColor={'red'}
                onClick={() => sdk.templateStore.reset()}
                buttonOption={{
                    widthPx: 180,
                    heightPx: 96,
                    lineHeight: 80,
                    heightToWidth: undefined,
                    margin: [16, 16, 16, 64],
                    padding: [0, 8, 0, 8],
                    textAlign: 'center'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [16, 0, 0, 0]
                }}
                icon={'./data/icon/previous.svg'}
            >
                Back
            </ActionButton>
            : (
                <>
                    <ActionButton
                        actionColor={'red'}
                        onClick={() => clock.closeAdmin()}
                        buttonOption={{
                            widthPx: 180,
                            heightPx: 96,
                            heightToWidth: undefined,
                            margin: [16, 16, 16, 16]
                        }}
                    >
                        Leave
                    </ActionButton>
                    <ActionButton
                        actionColor={'green'}
                        onClick={() => clock.saveAdmin(store)}
                        disabled={!store?.changed}
                        buttonOption={{
                            widthPx: 180,
                            heightPx: 96,
                            heightToWidth: undefined,
                            margin: [16, 0, 16, 16]
                        }}
                    >
                        Save
                    </ActionButton>
                </>
            )}
    </>
})

export default ClockEditor;