
const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=<pck_name>';
const APPSTORE_URL = 'https://apps.apple.com/app/<store-NAME>/id<store-ID>';
const CLOCK_URL = process.env.REACT_APP_CLOCK_URL || 'https://clock.bullets.poker/';

function openBulletsPokerApp() {
    if(process.env.REACT_APP_OSTYPE === 'ios') {
        return openIosApp(process.env.REACT_APP_BP_APPSTORE_ID, process.env.REACT_APP_BP_APPSTORE_NAME);
    } else if(process.env.REACT_APP_OSTYPE === 'android') {
        return openAndroidApp(process.env.REACT_APP_BP_PLAYSTORE_ID);
    } 
    return openWebsite(`${process.env.REACT_APP_WEBSITE_BP}`);
}

function openBulletsPokerClockApp() {
    if(process.env.REACT_APP_OSTYPE === 'ios') {
        return openIosApp(process.env.REACT_APP_APPSTOREIOS_ID, process.env.REACT_APP_APPSTOREIOS_NAME);
    } else if(process.env.REACT_APP_OSTYPE === 'android') {
        return openAndroidApp(process.env.REACT_APP_PLAYSTORE_ID);
    } 
    return openWebsite(`${process.env.REACT_APP_WEBSITE}`);
}

function openAndroidApp(appId: string | undefined) {
    
    if (appId) {
        window.open(PLAYSTORE_URL.replace('<pck_name>', appId));
    }
    return true;
}

function openIosApp(appId: string | undefined, appName: string | undefined) {
    if (appId && appName) {
        window.open(APPSTORE_URL
            .replace('<store-ID>', appId)
            .replace('<store-NAME>', appName)
        )
    }
    return true;
}

const openWebsite = (url: string) => {
    try {
        window.open(url);
    } catch (e) { }

    return true;
}

const openMail = (mail: string) => {
    try {
        window.open(`mailto: ${mail}`);
    } catch (e) { }

    return true;
}

export {
    openBulletsPokerApp,
    openBulletsPokerClockApp,
    openIosApp,
    openAndroidApp,
    openWebsite,
    openMail,
    CLOCK_URL
}