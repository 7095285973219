import {
    observer
} from 'mobx-react-lite';
import { ActionButton, ImageComponent, Container } from '../../controls';
import {
    canShare,
    onShare
} from '../../../utils/sharing';
import {
    openMail,
    CLOCK_URL
} from '../../../utils/urls';

const ClockSummary = observer(({ sdk }: any) => {

    const validClock = !!sdk?.clockWizardData?.code;

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Clockcode
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 28
                }}
            >
                {validClock ?
                    sdk.clockWizardData.code :
                    `- Not created -`
                }
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Auth
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 28
                }}
            >
                {sdk.clockWizardData.auth}
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Open clock in browser
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 28
                }}
            >
                {validClock ? (
                    <a href={`${CLOCK_URL}?code=${sdk.clockWizardData.code}`} target={'_blank'} style={{ color: '#fff' }} rel="noreferrer">
                        {`${CLOCK_URL}?code=${sdk.clockWizardData.code}`}
                    </a>
                ) : null}
            </Container>
            <ActionButton
                actionColor={'orange'}
                onClick={() => {
                    const code = sdk.clockWizardData.code;
                    const auth = sdk.clockWizardData.auth;
                    sdk.closeWizard();
                    sdk.addClock(code, auth)
                }}
                disabled={!validClock}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    heightToWidth: undefined,
                    margin: [16, 0, 16, 32],
                    padding: [0, 8, 0, 8],
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [16, 0, 0, 0]
                }}
                icon={'./data/icon/admin.png'}
            >
                Open as admin
            </ActionButton>
            {canShare() ? (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => onShare('Open the poker clock on your device', 'Open the poker clock on your device', sdk.clockWizardData.code)}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        heightToWidth: undefined,
                        margin: [16, 0, 16, 32],
                        padding: [0, 8, 0, 8],
                    }}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [16, 0, 0, 0]
                    }}
                    disabled={!validClock}
                    icon={'./data/icon/share.svg'}
                >
                    Share clock
                </ActionButton>
            ) : null}
            {sdk.user.premiumUser ? (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => {
                        if (sdk.clockWizardData.config) {
                            sdk.templateStore.saveTemplate(sdk, 'tournament', sdk.clockWizardData.tmp);
                        }
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        heightToWidth: undefined,
                        margin: [16, 0, 16, 32],
                        padding: [0, 8, 0, 8],
                    }}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [16, 0, 0, 0]
                    }}
                    icon={'./data/icon/diskette.svg'}
                >
                    Save Tournament
                </ActionButton>
            ) : (
                <ImageComponent
                    source={`./data/ads/clockpremium.png`}
                    imageOption={{
                        widthPx: 620,
                        // heightPx: 418,
                        margin: [0, 0, 0, 0]
                    }}
                    onClick={() => {
                        openMail('marc@bullets.poker')
                    }}
                />
            )}

        </Container>
    </>;
});

export default ClockSummary;
