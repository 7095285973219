import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx'

function isValidHttpUrl(n: string) {
    let url;

    try {
        url = new URL(n);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export default class GeneralStore {

    public adminMode: boolean;
    public h1Label: string | null;
    public h2Label: string | null;
    public currency: string;
    public allowCurrency: number;
    public country: string;
    public password: string;
    public stylecode: null | string;
    public logo: string;

    constructor(admin: boolean = false) {
        makeObservable(this, {
            h1Label: observable,
            h2Label: observable,
            currency: observable,
            allowCurrency: observable,
            country: observable,
            password: observable,
            stylecode: observable,
            adminMode: observable,
            logo: observable,
            updateStyleCode: action,
            reset: action,
            updateValue: action,
            load: action,
            isCurrencyValid: computed,
            currencyPreview: computed,
            changed: computed,
            storeValues: computed,
            isValid: computed
        })

        this.adminMode = admin;
        this.h1Label = '';
        this.h2Label = '';
        this.currency = 'EUR';
        this.country = 'DE';
        this.password = '';
        this.logo = '';
        this.stylecode = '';
        this.allowCurrency = 1;
    }

    load(clockData: any, password: string = "") {

        this.h1Label = clockData?.config?.lblH1 || '';
        this.h2Label = clockData?.config?.lblH2 || '';
        this.currency = clockData?.config?.currency || 'EUR';
        this.allowCurrency = clockData?.config?.flagCurrency === 0 ? 0 : (clockData?.config?.allowCurrency || 1);
        this.country = clockData?.config?.country || 'DE';
        this.password = password;
        this.logo = clockData?.config?.logo || '';
        this.stylecode = null;

        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get storeId() {
        return 'general'
    }

    get config() {
        return {
            lblH1: this.h1Label,
            lblH2: this.h2Label,
            logo: this.logo,
            country: this.country,
            currency: this.currency,
            flagCurrency: this.allowCurrency
        }
    }

    get updatedConfig() {
        return {
            lblH1: this.h1Label,
            lblH2: this.h2Label,
            logo: this.logo,
            country: this.country,
            currency: this.currency,
            flagCurrency: this.allowCurrency
        }
    }

    get storeValues() {
        return `${this.h1Label}${this.h2Label}${this.currency}${this.allowCurrency}${this.country}${this.logo}`
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }
        return this.storeValues !== compareStore;
    }

    public updateStyleCode(code:string): void {
        this.stylecode = code;
    }

    // Reset the store
    public reset(): void {
        this.h1Label = '';
        this.h2Label = '';
        this.currency = 'EUR';
        this.country = 'DE';
        this.password = '';
        this.logo = '';
        this.stylecode = '';
        this.allowCurrency = 1;
    }

    get isValid() {
        return this.isCurrencyValid &&
            this.h1Label &&
            (this.password === '' || this.password.length >= 3) &&
            (!this.logo || isValidHttpUrl(this.logo));
    }

    get isCurrencyValid() {
        if (this.currency === 'EUR') return true;
        if (this.currency === 'GBP') return true;
        if (this.currency === 'USD') return true;
        if (this.currency === 'CHF') return true;
        return false;
    }

    get currencyPreview() {
        if (this.country.length === 2 && this.isCurrencyValid) {
            return new Intl.NumberFormat(`${this.country}-${this.country}`, !this.allowCurrency ? {} : { style: "currency", currency: this.currency }).format(125000.65)
        }
        return '- No Preview -'
    }
}