import {
    observer
} from 'mobx-react-lite';
import {
    useEffect
} from 'react';
import ClockMainMenu from './views/ClockMainMenu';
import ClockAdmin from './views/clockadmin/ClockAdmin';
import ClockView, {
    ClockViewRotated
} from './views/ClockView';
import LoadingSpinner from './LoadingSpinner';
import ClockWizard from './views/ClockWizard';
import ClockLogin from './views/ClockLogin';
import Account from './views/account/Account';
import RoleEditor from './views/editor/RoleEdit';

import NumberDialog from './dialog/NumberDialog';
import StringDialog from './dialog/StringDialog';
import MessageDialog from './dialog/MessageDialog';

import { VIEWS } from '../api';

const GameCanvas = observer(({ sdk }: any) => {
    const { layoutManager } = sdk;

    useEffect(() => {
        // Update the layoutmanager to fit the margin of the client
        layoutManager.updateScreen(window);
    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_ROTATESCREEN === 'true' && sdk.layoutManager) {
            if(sdk.view === VIEWS.Clock) {
                sdk.layoutManager.showVerticalCentered(true)
            } else {
                sdk.layoutManager.showVerticalCentered(false)
            }
        }
    }, [sdk?.layoutManager, sdk?.view]);

    return <>
        <div
            id={'gamecanvas'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            {sdk.view === VIEWS.Mainmenu ? <ClockMainMenu sdk={sdk} /> : null}
            {sdk.view === VIEWS.Admin ? <ClockAdmin sdk={sdk} /> : null}
            {sdk.view === VIEWS.RoleEditor ? <RoleEditor sdk={sdk} /> : null}
            {sdk.view === VIEWS.Account ? <Account sdk={sdk} /> : null}
            {sdk.view === VIEWS.ClockLogin ? <ClockLogin sdk={sdk} /> : null}
            {sdk.view === VIEWS.Clock && process.env.REACT_APP_ROTATESCREEN === 'false' ? <ClockView sdk={sdk} /> : null}
            {sdk.view === VIEWS.Clock && process.env.REACT_APP_ROTATESCREEN === 'true' ? <ClockViewRotated sdk={sdk} /> : null}
            {sdk.view === VIEWS.Wizard ? <ClockWizard sdk={sdk} /> : null}
            {sdk.spinner ? <LoadingSpinner sdk={sdk} /> : null}
        </div>
        <NumberDialog
            sdk={sdk}
        />
        <StringDialog
            sdk={sdk}
        />
        <MessageDialog
            sdk={sdk}
        />
    </>
});

export default GameCanvas;