import { EVENT_TYPE } from "../constants/events";
import { SOUNDS } from "../constants/sounds";
import { timestampMs } from "../utils/helper";

export class SoundManager {
    public soundList: any;

    constructor() {
        this.soundList = {};
        (SOUNDS || []).forEach((snd: any) => {
            this.soundList[snd.value] = {
                audio: new Audio(`./data/sound/${snd.value}`)
            }
        })
    }

    playSound(sound: string): void {
        if (this.soundList[sound]) {
            try {
                this.soundList[sound].audio.play().then(() => {
                    console.log('PLAYED')
                }).catch((e: any) => {
                    console.log('NOT PLAYED', e)
                });
            } catch (e) {
                console.log(e)
            }
        }
    }
}
class EventManager {
    private _sdk: any;
    public eventList: any;
    public latestEvent: string;
    public latestEventTime: number;


    constructor(sdk: any) {
        this._sdk = sdk;
        this.eventList = [];
        this.latestEvent = '';
        this.latestEventTime = 0;
    }

    public updateConfig(cfg: any): void {
        this.eventList = cfg;
    }

    public trigger(eventId: string): void {
        if (this.latestEvent === eventId && (timestampMs() - this.latestEventTime) < 2000) {
            return;
        }
        this.latestEvent = eventId;
        this.latestEventTime = timestampMs();

        console.log('trigger', eventId)
        const findEvent = this.eventList.find((event: any) => event[0] === eventId);

        if (findEvent) {
            if (findEvent[1] === EVENT_TYPE.EVENT_SOUND) {
                this._sdk.sounds.playSound(findEvent[2]);
            }
        }
    }

}

export default EventManager;